<template>
    <div v-if="info">
        <vue-header :title="show?'用户资料':'个人名片'" isReturn="true" />
        <ul class="profile-tion">
            <li class="profile-li flex flex-y">
                <div class="flex-1">头像</div>
                <div class="profile-png">
                    <label>
                        <input type="file" name="UL_image" id="UL_image" accept=".png, .jpg, .jpeg, .gif, .bnp, image/jpeg, image/png, image/gif" multiple class="none" @change="imageChange"/>
                        <span>
                            <img :src="info.avatarUrl" v-if="info.avatarUrl"/>
                            <img src='../../../images/user.png' v-else />
                        </span>
                    </label>
                </div>
            </li>
            <li class="profile-li flex flex-y" @click="nickFun">
                <div class="flex-1">昵称</div>
                <div class="profile-text">{{info.nikeName}}</div>
                <van-icon name="arrow" />
            </li>
            <li class="profile-li flex flex-y">
                <div class="flex-1">所属公司</div>
                <div class="profile-text">{{info.company_name}}</div>
            </li>
            <li class="profile-li flex flex-y">
                <div class="flex-1">手机</div>
                <div class="profile-iphone">{{info.phone}}</div>
            </li>
            <li class="profile-li flex flex-y" v-if="show">
                <div class="flex-1">资质证书</div>
                <div class="profile-iphone" v-if="info.doctor_material==0||info.doctor_material==2||!info.doctor_material">未上传或审核不通过</div>
                <div class="profile-iphone" v-else-if="info.doctor_material==3">已上传</div>
                <div class="profile-iphone" v-else-if="info.doctor_material==1">审核通过</div>
            </li>
            <li class="profile-li flex flex-y" @click="axiosAddress" v-if="show">
                <div class="flex-1">地址</div>
                <div class="profile-text" v-if="info.province && info.region">{{`${info.province}${info.region}`}}</div>
                <van-icon name="arrow" />
            </li>
        </ul>
        <div v-if="nickBool" class="alert">
            <div class="alert-hoot">
                <div class="alert-title">修改昵称</div>
                <div class="alert-input">
                    <input type="text" placeholder="请输入昵称" v-model="nickName" />
                </div>
                <div class="alert-footer flex">
                    <div class="cancel flex-1" @click="nickFun">
                        取消
                    </div>
                    <div class="flex-1" @click="userReviseData">
                        确定
                    </div>
                </div>
            </div>
        </div>
        <div class="shadow" v-if="address">
            <div class="picker-bottom white" v-on:click.stop>
                <van-cascader
                    title="请选择所在地区"
                    :options="addressOption"
                    @close="closeFun"
                    @finish="finishFun"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState  , mapActions , mapMutations } from 'vuex';
import vueHeader from '@/components/header'
export default {
  name: 'Profile',
  components:{
    vueHeader
  },
  data() {
    return {
      show:false
    };
  },
  created:function(){
      this.axiosMain({},this);
      if(!this.$route.query.red){
            this.show=true
      }
  },
  computed:{
        nickName:{
            get() { return this.$store.state.profile.nickName },
            set(val){this.change({
                key:'nickName',
                value:val
            });}
        },
      ...mapState('profile',['hospital','switchBool','nickBool','info','address','addressOption']),
  },
  methods:{
      back(){
          window.history.back(-1);
      },
      imageChange(e){
          this.setImage(e)
      },
      closeFun(){
          this.change({
              key:'address',
              value:false
          })
      },
      //结束
      finishFun({ selectedOptions }){
          let cityArray = selectedOptions.map((option) => option.value);
          this.change({key:'province',value:`${cityArray[0]}`});
          this.change({key:'region',value:`${cityArray[1]}`});
          this.change({key:'address',value:false});
          this.userReviseData();
      },
      ...mapMutations('profile',['nickFun','change']),
      ...mapActions('profile',['userReviseData','axiosMain', 'setImage','axiosAddress']),
  }
}

</script>

<style lang="scss" scoped rel="stylesheet/scss">
  @import 'profile';
</style>
